<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../../assets/common/pic/bgss.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">无线视频传输应用（视距、固定点）</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LINKOM推出的室外系列网桥可应用于1KM-60KM的点对点、点对多点及移动无线视频传输，
          高宽带、安全抗干扰及天线内外置可选设计，搭配无线网络管理软件平台内置google地图引擎，
          实时定位部署在线监测，时间告警等管理功能，广泛应用于智能楼宇、交通运输、渡轮码头、森林防火等安防视频传输领域。<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点对点、点对多点高带宽传输，支持高达900Mbps高带宽传输；<br>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;点对点可支持50路D1视频传输，可选私有协议I-Poll及W-Jet抗干扰双频5.8G/2.4G传输，点对多点可支持20点分点客户端摄像机连接。
        </div>
        <div class="pic">
          <img src="../../../assets/solution/1.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Monitoring',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>

<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 290px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 760px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}

.switchContiner .contactContiner .pic {
  margin-top: 40px;
  text-align: center;
}

.switchContiner .contactContiner .pic img {
  width: 500px;
}
</style>
